import React, { ReactElement } from "react";
import style from "./style.module.scss";
export default function Benefits(): ReactElement {
    return (
        <section className={`${style["mainSection"]} `}>
            <div className="py-20 px-2">
                <h2 className="text-center mx-auto">
                    An affordable car warranty plan for every budget with little
                    to no upfront fees. Fill out our form to get the best plan
                    for you.
                </h2>
            </div>
        </section>
    );
}
